import React from 'react';
import { graphql } from 'gatsby';

import InfoBox from '../components/global/info-box/InfoBox';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import Customers from '../components/global/customers/Customers';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Snippet from '../components/global/snippet/Snippet';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import InfoCard from '../components/global/InfoCard/InfoCard.component';

import { Container } from '../components/global/customers/styles';
import { SectionWrapper } from '../components/mid-market/styles';
import {
	ImageHolder,
	Section,
} from '../components/home-page/about-chatbots/styles';
import * as S from '../components/demand-generation/styles';
import { SliderContainer } from '../components/customer-support/styles';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';
import { WhatsappCTAWrapper } from '../components/whatsapp-chatbot/styles';

const newWhatsappChatbotTemplate = ({ data }) => {
	const {
		heroTitle,
		heroSubtitle,
		heroImage,
		buttonText,
		buttonUrl,
		customerTitle,
		customerImages,
		section1Title,
		section1Subtitle,
		section1Images,
		cta1,
		section2Cards,
		section3Title,
		section3Cards,
		cta2,
		seoTitle,
		seoDescription,
		seoImage,
		seoArticle,
		noIndex,
		seoJsonSchema,
	} = data.contentfulPageNewWhatsappChatbotTemplate;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				noIndex={noIndex}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<InfoBox
					image={heroImage}
					title={heroTitle}
					subtitle={heroSubtitle.heroSubtitle}
					hideImage="true"
					buttonText={buttonText}
					buttonLink={buttonUrl}
					isHeroHeader
				/>
			</WrapperOne>
			<Customers
				section2Images={customerImages}
				section2Title={customerTitle}
			/>
			<Container>
				<Section>
					<SectionTextBox
						title={section1Title}
						subtitle={section1Subtitle.section1Subtitle}
						largeHeading="true"
					/>
				</Section>
				<SectionWrapper>
					<ImageHolder>
						{section1Images.map(info => (
							<Snippet
								url={info.file.url}
								title={info.title}
								subtitle={info.description}
							/>
						))}
					</ImageHolder>
				</SectionWrapper>
				<WhatsappCTAWrapper noBottomPadding={true}>
					<MasterCTA cta={cta1} />
				</WhatsappCTAWrapper>
				{section2Cards.map(section => (
					<S.SectionWrapper>
						<InfoBox
							image={section.image}
							title={section.title}
							subtitle={section.subtitle.subtitle}
							invert={!section.keepImageOnRight}
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</S.SectionWrapper>
				))}
				<SliderContainer>
					<SectionTextBox title={section3Title} largeHeading="true" />
				</SliderContainer>
				<InfoCardGrid style={{ marginTop: '20px' }}>
					{section3Cards.map(card => (
						<InfoCard
							image={card.icon}
							title={card.title}
							subtile={card.description.description}
							linkText={card.linkText}
							linkUrl={card.linkUrl}
						/>
					))}
				</InfoCardGrid>
				<CTAWrapper>
					<MasterCTA cta={cta2} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String) {
		contentfulPageNewWhatsappChatbotTemplate(slug: { eq: $slug }) {
			heroTitle
			heroSubtitle {
				heroSubtitle
			}
			heroImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			buttonText
			buttonUrl
			customerTitle
			customerImages {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section1Title
			section1Subtitle {
				section1Subtitle
			}
			section1Images {
				file {
					url
				}
				title
				description
			}
			cta1 {
				type
				title
				subtitle
				primaryButtonText
				primaryButtonUrl
				secondaryButtonText
				secondaryButtonUrl
			}
			section2Cards {
				keepImageOnRight
				title
				subtitle {
					subtitle
				}
				image {
					title
					description
					file {
						url
						contentType
					}
					fluid {
						...GatsbyContentfulFluid
					}
				}
			}
			section3Title
			section3Cards {
				title
				description {
					description
				}
				icon {
					file {
						url
					}
					title
				}
				linkText
				linkUrl
			}
			cta2 {
				type
				title
				subtitle
				primaryButtonText
				primaryButtonUrl
				secondaryButtonText
				secondaryButtonUrl
			}
			seoTitle
			seoDescription
			seoImage {
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
			}
			slug
			seoArticle
			noIndex
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;

export default newWhatsappChatbotTemplate;
